import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
// Landing pages:
import Home from "./pages/Home";
import Create from "./pages/Create";
import Entertining from "./pages/LearnMore";
import PageNotFound from "./pages/PageNotFound";
import Working from "./pages/Working";
import Values from "./pages/Values";
import Investor from "./pages/Investor";
import Fiction from "./pages/Fiction";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import firebase from "firebase";
import "firebase/auth";
import Creation from "./pages/Creation";
import Quorum from "./pages/Quorum";
import User from "./pages/User";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);

  return (
    <Switch>
      <Route exact path='/home' render={() => <Redirect to={"/"} />} />

      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/creating'>
        <Create />
      </Route>
      <Route exact path='/fiction-learn-more'>
        <Entertining />
      </Route>
      <Route exact path='/working'>
        <Working />
      </Route>
      <Route exact path='/values'>
        <Values />
      </Route>
      <Route exact path='/investors'>
        <Investor />
      </Route>
      <Route exact path='/fiction'>
        <Fiction />
      </Route>
      <Route exact path='/creation=*'>
        <Creation />
      </Route>
      <Route exact path='/user=*'>
        <User />
      </Route>
      <Route exact path='/quorum'>
        <Quorum />
      </Route>
      <Route exact path='/*'>
        <PageNotFound />
      </Route>
      {/* Protected routes are enables only when user signs in */}
    </Switch>
  );
};

export default App;
