import React, { useState } from "react";
import { Link } from "react-router-dom";

function InvestorPartial() {
  return (
    <div>
      <div className='flex flex-wrap m-auto align-center justify-items-center text-center w-full pt-20 pl-10 pr-10'>
        {/* Hero content */}
        <div className='w-full flex flex-col justify-center'>
          <div
            className='lg:text-5xl md:text-4xl sm:text-3xl font-semibold leading-tighter tracking-tighter mb-4 md:text-center sm:text-left mx-1 w-full'
            data-aos='fade-up'>
            <p className='flex flex-col text-purple-accent'>Investors</p>
          </div>
          <div
            className='lg:text-4xl md:text-3xl sm:text-2xl font-semibold leading-tighter tracking-tighter mb-4 md:text-center sm:text-left mx-1 w-full'
            data-aos='fade-up'>
            <p className='flex flex-col justify-center'>
              You're in control. Invest in what matters.
            </p>
          </div>
          <div className='lg:px-24 lg:mx-0 md:mx-0 md:px-24 sm:px-0'>
            <div
              class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 auto-rows-max gap-8 pt-24 pb-8'
              data-aos='fade-up'>
              <div>
                <p className='lg:text-3xl md:text-3xl sm:text-xl font-semibold leading-tighter tracking-tighter mb-4 mx-1 w-full text-left'>
                  Our Conviction
                </p>
                <p className='text-left text-gray-500 text-xl'>
                  Are you looking for a company that will change the world of
                  content creation? Look no further! Quasi is on the cutting
                  edge of generative AI, and we are poised for explosive growth.{" "}
                  <br />
                  <br />
                  Our intuitive platform and powerful tools make it easy for
                  users to unleash their creativity and to explore new and
                  innovative ways to create. And our commitment to continuous
                  improvement ensures that our technology stays ahead of the
                  curve and provides our users with the best possible
                  experience.
                  <br />
                  <br /> AI will never replace a person, but it will replace a
                  person who doesn’t use AI.
                </p>
              </div>
              <div className='flex justify-center w-full'>
                <img
                  src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Robot_Hero.webp'
                  height={500}
                  width={500}
                />
              </div>
            </div>
            <div class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 auto-rows-max gap-8 pb-8'>
              <div
                class='w-full bg-purple-level1 text-purple-text'
                data-aos='fade-up'>
                <div className='p-8'>
                  <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                    Metrics
                  </p>
                  <div>
                    <p className='lg:text-2xl md:text-xl sm:text-md flex flex-col text-left font-medium w-full'>
                      • 2100 Users (+3% Every Week) <br />
                      <br /> • 50,000 Creations Made <br />
                      <br />• 300 Use Cases Available
                    </p>
                  </div>
                  <div className='flex md:justify-center lg:justify-end'>
                    <img
                      src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Metrics_Hero_1.webp'
                      width={300}
                    />
                  </div>
                </div>
              </div>
              <div class='w-full bg-gray-level1' data-aos='fade-up'>
                <div className='p-8'>
                  <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                    Partnered Companies
                  </p>
                  <div className='h-full'>
                    <div class='flex items-center'>
                      <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Company_Hero.webp' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 auto-rows-max gap-8 pb-8'>
              <div class='w-full bg-gray-level1' data-aos='fade-up'>
                <div className='p-8'>
                  <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                    Join Our Mailing List
                  </p>
                  <div>
                    <p className='lg:text-2xl md:text-xl sm:text-md flex flex-col text-left font-medium w-full'>
                      Monthly Investor Updates
                    </p>
                  </div>
                  <div className='flex justify-start pt-3'>
                    <Link
                      to='/investors'
                      className={
                        "btn-sm font-medium text-purple-text bg-purple-level2 hover:bg-purple-level3 hover:text-purple-text2 rounded-2xl"
                      }>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.5 11.5723L15.4902 1.77734C15.4902 1.01562 14.9824 0.498047 14.2109 0.498047H4.40625C3.68359 0.498047 3.15625 1.04492 3.15625 1.69922C3.15625 2.34375 3.71289 2.87109 4.38672 2.87109H7.93164L11.7109 2.73438L9.96289 4.28711L0.949219 13.3105C0.695312 13.5645 0.548828 13.8867 0.548828 14.1992C0.548828 14.834 1.13477 15.4395 1.78906 15.4395C2.11133 15.4395 2.41406 15.3027 2.67773 15.0391L11.7012 6.02539L13.2637 4.27734L13.1074 7.91016V11.5918C13.1074 12.2754 13.6348 12.832 14.2891 12.832C14.9434 12.832 15.5 12.2852 15.5 11.5723Z'
                          fill='#2C0147'
                        />
                      </svg>
                      <span className='pl-2'>Learn More</span>
                    </Link>
                  </div>
                  <div className='flex md:justify-center lg:justify-end'>
                    <img
                      src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Mailing_List_Hero_1.webp'
                      width={300}
                    />
                  </div>
                </div>
              </div>
              <div
                class='w-full bg-purple-level1 text-purple-text'
                data-aos='fade-up'>
                <div className='p-8'>
                  <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                    Contact Us
                  </p>
                  <div>
                    <p className='lg:text-2xl md:text-xl sm:text-md flex flex-col text-left font-medium w-full'>
                      We would love to chat with you!
                    </p>
                  </div>
                  <div className='flex justify-start pt-3'>
                    <a
                      href='mailto:info@example.com'
                      className={
                        "btn-sm font-medium text-purple-text bg-purple-level2 hover:bg-purple-level3 hover:text-purple-text2 rounded-2xl"
                      }>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.5 11.5723L15.4902 1.77734C15.4902 1.01562 14.9824 0.498047 14.2109 0.498047H4.40625C3.68359 0.498047 3.15625 1.04492 3.15625 1.69922C3.15625 2.34375 3.71289 2.87109 4.38672 2.87109H7.93164L11.7109 2.73438L9.96289 4.28711L0.949219 13.3105C0.695312 13.5645 0.548828 13.8867 0.548828 14.1992C0.548828 14.834 1.13477 15.4395 1.78906 15.4395C2.11133 15.4395 2.41406 15.3027 2.67773 15.0391L11.7012 6.02539L13.2637 4.27734L13.1074 7.91016V11.5918C13.1074 12.2754 13.6348 12.832 14.2891 12.832C14.9434 12.832 15.5 12.2852 15.5 11.5723Z'
                          fill='#2C0147'
                        />
                      </svg>
                      <span className='pl-2'>Email Shantanu (Co-Founder)</span>
                    </a>
                  </div>
                  <div className='flex md:justify-center lg:justify-end'>
                    <img
                      src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Contact_Us_Hero_1.webp'
                      width={300}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestorPartial;
