import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import Footer from "../partials/Footer";

function PageNotFound() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("PageNotFound");
    logEvent("page_view", { page_name: "PageNotFound" });
  }, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Page Not Found</title>
        <meta name='description' content='Quasi | Page Not Found' />
        <meta property='og:title' content='Quasi | Page Not Found' />
      </MetaTags>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className='flex-grow'>
        <section className='relative'>
          {/* Illustration behind content */}

          <div className='max-w-6xl mx-auto px-4 sm:px-6'>
            <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
              <div className='max-w-3xl mx-auto text-center'>
                {/* 404 content */}
                <strong className='text-6xl text-blue-accent'>Uh Oh.</strong>
                <h1 className='h1 mb-4'>There seems to be a problem</h1>
                <div className='mt-8'>
                  <div className='flex lg:flex-row md:flex-col sm:flex-col'>
                    <Link
                      to='/Home'
                      className='btn-sm w-full font-medium pl-8 pr-8 text-blue-text bg-blue-level2 hover:bg-blue-level3 hover:text-blue-text ml-3 focus:outline-none rounded-squircle'>
                      Go back home
                    </Link>
                    <div className='lg:my-0 md:my-2 ms:my-2'></div>
                    <Link
                      to='/Home'
                      className='btn-sm w-full font-medium pl-8 pr-8 text-gray-text2 bg-gray-level2 hover:bg-gray-level3 hover:text-gray-text2 ml-3 focus:outline-none rounded-squircle'>
                      Tell Us What Happened
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default PageNotFound;
