import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import FictionPartial from "../partials/FictionPartial";
import FictionFooter from "../partials/FictionFooter";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const Fiction = (props) => {
  // Sets screen analytics
  useEffect(() => {
    logFirestoreEvent("HomePageView", {});
    window.scroll(0, 0);
    logScreenName("Home");
    logEvent("page_view", { page_name: "Home" });
  }, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Ventures</title>
        <meta name='description' content='Quasi Venture | Fiction' />
        <meta property='og:title' content='Quasi Fiction' />
        <meta
          name='apple-itunes-app'
          content='app-id=1662191941'
        />
      </MetaTags>

      {/*  Site header */}
      {/* <FictionHeader /> */}
      {/*  Page content */}
      <main className='bg-gray-black'>
        {/*  Page sections */}

        <FictionPartial />
      </main>

      {/*  Site footer */}
      <FictionFooter />
    </div>
  );
};

export default Fiction;
