import React from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const FictionFooter = () => {
  return (
    <footer>
      <div className='max-w-max mx-auto'>
        {/* </div> */}

        {/* Bottom area */}
        <div className=''>
          <div
            className='relative h-screen bg-cover bg-center'
            style={{
              backgroundImage:
                "url(https://storage.googleapis.com/quasi-a39a6.appspot.com/fiction_space.webp)",
            }}>
            <div className='absolute inset-0 flex flex-col justify-center'>
              <div className='flex md:flex-row sm:flex-col md:justify-around sm:justify-center'>
                <div className='flex flex-row justify-center'>
                  <div className='mx-4 flex flex-col justify-center sm:mt-32 md:mt-0'>
                    <img
                      data-aos='fade-up'
                      src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Fiction_Header.png'
                      height={200}
                      width={200}
                      target='_blank'></img>
                    <div className='mb-8'></div>
                    <a href='https://airtable.com/shrCOgsI67sGHGmhy'>
                      <img
                        data-aos='fade-up'
                        src='https://storage.googleapis.com/quasi-a39a6.appspot.com/App_Store.png'
                        height={200}
                        width={200}
                        target='_blank'></img>
                    </a>
                  </div>
                </div>
                <div className='flex md:flex-row sm:flex-col'>
                  <div className='mx-4 flex flex-col my-8' data-aos='fade-up'>
                    <div className='font-bold text-2xl text-white sm:text-center md:text-left'>
                      Get
                    </div>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://apps.apple.com/us/app/fiction-ai-entertainment/id1662191941'
                      target='_blank'>
                      Download App
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://airtable.com/shrCOgsI67sGHGmhy'
                      target='_blank'>
                      Join Beta
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://airtable.com/shrRYQ5F2Lf4SjWR3'
                      target='_blank'>
                      Get Email Updates
                    </a>
                  </div>
                  <div className='mx-4 flex flex-col my-8' data-aos='fade-up'>
                    <div className='font-bold text-2xl text-white sm:text-center md:text-left'>
                      Company
                    </div>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.notion.so/quasiventures/Quasi-Support-23541e66461c47618bb6efae078a00a2?pvs=4'
                      target='_blank'>
                      Help & FAQ
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.notion.so/quasiventures/Terms-Of-Service-c71dcf58ee214a36a5999c9955d07e4c?pvs=4'
                      target='_blank'>
                      Terms of Service
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.notion.so/quasiventures/Privacy-Policy-339fddf12ed64c188fdcaa6ce26a7255?pvs=4'
                      target='_blank'>
                      Privacy Policy
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.notion.so/quasiventures/About-57b8210cf58544e58a8d7c8607404bcf?pvs=4'
                      target='_blank'>
                      About Quasi
                    </a>
                  </div>
                  <div className='mx-4 flex flex-col my-8' data-aos='fade-up'>
                    <div className='font-bold text-2xl text-white sm:text-center md:text-left'>
                      Contact
                    </div>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.notion.so/quasiventures/Contact-ba42348e316a44b9b545b0d569e2730c?pvs=4'
                      target='_blank'>
                      Support
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://twitter.com/quasi_ventures'
                      target='_blank'>
                      Twitter
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.instagram.com/quasiventures'
                      target='_blank'>
                      Instagram
                    </a>
                    <a
                      className='text-xl text-white hover:underline sm:text-center md:text-left'
                      href='https://www.linkedin.com/company/quasiventures/'
                      target='_blank'>
                      Press Kit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FictionFooter;
