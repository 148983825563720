import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import LearnMorePartial from "../partials/LearnMorePartial";
import FictionHeader from "../partials/FictionHeader";
import FictionFooter from "../partials/FictionFooter";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const Entertining = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Ventures</title>
        <meta name='description' content='Quasi Venture Nexus Page' />
        <meta property='og:title' content='Quasi | Ventures' />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className='flex-grow bg-gray-black'>
        {/*  Page sections */}
        <FictionHeader />

        <LearnMorePartial />
        {/* <FeaturesHome /> */}
      </main>

      {/*  Site footer */}
      <FictionFooter />
    </div>
  );
};

export default Entertining;
