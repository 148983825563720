import React, { useState, useRef, useEffect } from "react";
import Input from "../pages/components/Input";
import LongerTextArea from "../pages/components/LongerTextArea";
import { runQuorum } from "../merlinv1/beta_api";
import { uploadQuorum } from "../merlinv1/beta_api";

function QuorummPartial() {
  // message name, message prompt, save key, chunk bool, save location
  const [chain, setChain] = useState([["", "", "", false, "none", "string"]]);
  const [medium, setMedium] = useState("");
  const [components, setComponents] = useState("");
  const [componentIds, setComponentIds] = useState("");
  const [outputChain, setOutputChain] = useState([["", ""]]);
  const [chainLoading, setChainLoading] = useState([false]);
  const [defaultChunkingKey, setDefaultChunkingKey] = useState("");
  const [defaultChunkingValue, setDefaultChunkingValue] = useState("");

  const [temperature, setTemperature] = useState(0.5);
  const [topP, setTopP] = useState(1.0);
  const [promptingVersion, setPromptingVersion] = useState(1.1);
  const [disableButton, setDisableButton] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleNewChainItem = (index, chunkBool) => {
    let newChain = [...chain];
    newChain.splice(index + 1, 0, ["", "", "", chunkBool, "", ""]);
    setChain(newChain);
    let newOutputChain = [...outputChain];
    newOutputChain.splice(index + 1, 0, ["", ""]);
    setOutputChain(newOutputChain);
    let newChainLoading = [...chainLoading];
    newChainLoading.splice(index + 1, 0, false);
    setChainLoading(newChainLoading);
  };

  const handleRemoveChainItem = (index) => {
    let newChain = [...chain];
    newChain.splice(index, 1);
    setChain(newChain);
    let newOutputChain = [...outputChain];
    newOutputChain.splice(index, 1);
    setOutputChain(newOutputChain);
    let newChainLoading = [...chainLoading];
    newChainLoading.splice(index, 1);
    setChainLoading(newChainLoading);
  };

  const handleChangeChainItem = (index, sub_index, value) => {
    let newChain = [...chain];
    newChain[index][sub_index] = value;
    setChain(newChain);
    console.log(newChain);
  };

  const handleUploadQuorum = (chain) => {
    // Convert the 2D list into the desired JSON format
    const jsonData = {};
    const chainData = chain.map(([key, ...values]) => ({ [key]: values }));

    jsonData["medium"] = medium;
    jsonData["component_ids"] = componentIds.split(",");
    jsonData["chain"] = chainData;
    jsonData["temperature"] = temperature;
    jsonData["top_p"] = topP;
    jsonData["prompting_version"] = promptingVersion;

    jsonData["default_chunking_key"] = defaultChunkingKey;
    jsonData["default_chunking_value"] = defaultChunkingValue;

    // upload jsonData to the server
    uploadQuorum(jsonData);
  };

  const handleExportQuorum = (chain) => {
    // Convert the 2D list into the desired JSON format
    const jsonData = {};
    const chainData = chain.map(([key, ...values]) => ({ [key]: values }));

    jsonData["medium"] = medium;
    jsonData["component_ids"] = componentIds.split(",");
    jsonData["chain"] = chainData;
    jsonData["temperature"] = temperature;
    jsonData["top_p"] = topP;
    jsonData["prompting_version"] = promptingVersion;

    jsonData["default_chunking_key"] = defaultChunkingKey;
    jsonData["default_chunking_value"] = defaultChunkingValue;

    // Create a data URI for the JSON content
    const dataUri = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(jsonData, null, 2)
    )}`;

    // Create an anchor element to trigger the file download
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = medium + ".json";

    // Trigger the download
    downloadLink.click();
  };

  const handleImportQuorum = () => {
    // prompt the user to select a file then set the file to the state. the file is in the following format:
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("accept", ".json");
    fileSelector.click();
    fileSelector.onchange = () => {
      const file = fileSelector.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const data = JSON.parse(reader.result);
        console.log(data);

        const output = data["chain"].map((obj) => {
          const key = Object.keys(obj)[0];
          const values = obj[key];
          return [key, ...values];
        });

        // set the outputChain to the length of the chain but filled with ["", ""]
        const newOutputChain = [];
        for (let i = 0; i < output.length; i++) {
          newOutputChain.push(["", ""]);
        }
        console.log(newOutputChain);
        setOutputChain(newOutputChain);

        // set the chain to the output
        setChain(output);
        setMedium(data["medium"]);
        setComponentIds(data["component_ids"].join(","));
        setTemperature(data["temperature"]);
        setTopP(data["top_p"]);
        setPromptingVersion(data["prompting_version"]);
        setDefaultChunkingKey(data["default_chunking_key"]);
        setDefaultChunkingValue(data["default_chunking_value"]);
      };
      reader.onerror = () => {
        console.log(reader.error);
      };
    };
  };

  const handleChunkChain = (index) => {
    // set all chain item 3 indexed after and including the current index to true
    let newChain = [...chain];
    for (let i = index; i < newChain.length; i++) {
      newChain[i][3] = true;
    }
    setChain(newChain);
  };

  const handleUnchunkChain = (index) => {
    // set all chain item 3 indexed after and including the current index to false
    let newChain = [...chain];
    for (let i = index; i < newChain.length; i++) {
      newChain[i][3] = false;
    }
    setChain(newChain);
  };

  const handleRunChain = async (index) => {
    // set all chainLoading indexed before and including the current index to true
    let newChainLoading = [...chainLoading];
    for (let i = 0; i <= index; i++) {
      newChainLoading[i] = true;
    }
    setChainLoading(newChainLoading);

    // Convert the 2D list into the desired JSON format
    const jsonData = {};
    const chainData = chain.map(([key, ...values]) => ({ [key]: values }));
    // reduce the chain to the current index and everything above it
    const chainDataReduced = chainData.slice(0, index + 1);

    jsonData["medium"] = medium;
    jsonData["component_ids"] = componentIds.split(",");
    jsonData["chain"] = chainDataReduced;
    jsonData["temperature"] = temperature;
    jsonData["top_p"] = topP;
    jsonData["prompting_version"] = promptingVersion;
    jsonData["components"] = components.split(",");

    jsonData["default_chunking_key"] = defaultChunkingKey;
    jsonData["default_chunking_value"] = defaultChunkingValue;

    // Create a data URI for the JSON content
    const dataUri = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(chainDataReduced, null, 2)
    )}`;
    let res = await runQuorum(jsonData, components.split(","));
    res = res.data;
    console.log(res.response[index]);
    console.log(res.context);
    // set all outputChain indexed before and including the current index to the response
    let newOutputChain = [...outputChain];
    for (let i = 0; i <= index; i++) {
      newOutputChain[i] = res.response[i];
    }
    console.log(newOutputChain);
    setOutputChain(newOutputChain);

    // set all chainLoading indexed before and including the current index to false
    newChainLoading = [...chainLoading];
    for (let i = 0; i <= index; i++) {
      newChainLoading[i] = false;
    }
    setChainLoading(newChainLoading);
  };

  useEffect(() => {}, []);

  return (
    <div className=''>
      <div className='w-full flex flex-row justify-between'>
        <span className='text-left font-bold text-3xl'>
          Prompting <span className='text-purple-vibrant'>Wizard 🧙</span>
        </span>
        <div className=''>
          <div
            className={
              "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl mr-2"
            }
            onClick={(e) => {
              handleImportQuorum(e);
            }}>
            <span className=''>Import</span>
          </div>
          <div
            className={
              "btn-sm font-medium text-gray-white bg-purple-level3 hover:bg-purple-vibrant rounded-xl mb-8 mt-3"
            }
            onClick={() => handleExportQuorum(chain)}>
            <span className=''>Export</span>
          </div>
          <div
            className={
              "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl ml-2"
            }
            onClick={() => handleUploadQuorum(chain)}>
            <span className=''>Upload</span>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Medium Name'
            onChangeText={(text) => setMedium(text)}
            value={medium}
          />
        </div>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Temperature'
            type='number'
            onChangeText={(text) => setTemperature(text)}
            value={temperature}
          />
        </div>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Top P'
            type='number'
            onChangeText={(text) => setTopP(text)}
            value={topP}
          />
        </div>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Prompting Version'
            type='number'
            onChangeText={(text) => setPromptingVersion(text)}
            value={promptingVersion}
          />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Component Description'
            onChangeText={(text) => setComponentIds(text)}
            value={componentIds}
          />
        </div>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Components'
            onChangeText={(text) => setComponents(text)}
            value={components}
          />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Default Chunking Key'
            onChangeText={(text) => setDefaultChunkingKey(text)}
            value={defaultChunkingKey}
          />
        </div>
        <div className='w-full pr-4'>
          <Input
            className='rounded-2xl text-gray-level3 py-2'
            placeholder='Default Chunking Value'
            onChangeText={(text) => setDefaultChunkingValue(text)}
            value={defaultChunkingValue}
          />
        </div>
      </div>
      <div className='mb-8'></div>
      <div className='grid grid-cols-1 divide-x divide-gray-level3 mb-8 h-full'>
        <div className=''>
          {chain.map((item, index) => (
            <div className='mb-8'>
              {item[3] === false ? (
                <div className='grid grid-cols-1 gap-3 mt-3'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-row'>
                      <div className='pt-3 pr-2'>
                        <div
                          className={
                            "btn-sm font-medium text-gray-white bg-green-level3 hover:bg-green-level4 rounded-xl"
                          }
                          onClick={() => handleRunChain(index)}>
                          {chainLoading[index] ? (
                            <div className='flex items-center justify-center'>
                              <svg
                                role='status'
                                className='inline w-3 h-3 text-blue-generateSpin animate-spin'
                                viewBox='0 0 100 101'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                  fill='#8AC926'
                                />
                                <path
                                  d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                          ) : (
                            <svg
                              width='12'
                              height='11'
                              viewBox='0 0 16 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M1.78516 17.6992C2.21484 17.6992 2.5957 17.5527 3.08398 17.2695L14.6758 10.5508C15.5449 10.0527 15.9355 9.63281 15.9355 8.95898C15.9355 8.29492 15.5449 7.875 14.6758 7.36719L3.08398 0.648438C2.5957 0.365234 2.21484 0.21875 1.78516 0.21875C0.935547 0.21875 0.300781 0.873047 0.300781 1.91797V16C0.300781 17.0547 0.935547 17.6992 1.78516 17.6992Z'
                                fill='white'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div className='font-bold text-xl pt-3'>
                        Chain {index + 1} ⛓️
                      </div>
                    </div>
                    <div className=''>
                      <div className='flex flex-row justify-between'>
                        <div className='flex flex-row'>
                          <div className='pt-3 pr-2'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl"
                              }
                              onClick={() => {
                                handleChunkChain(index);
                              }}>
                              <svg
                                width='15'
                                height='11'
                                viewBox='0 0 20 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M19.3457 7.92969C19.3457 7.59766 19.209 7.26562 18.9648 7.03125L12.5684 0.644531C12.2949 0.371094 12.002 0.244141 11.6992 0.244141C10.9961 0.244141 10.498 0.742188 10.498 1.40625C10.498 1.75781 10.6445 2.05078 10.8691 2.27539L13.0566 4.48242L15.625 6.83594L13.418 6.70898H1.9043C1.16211 6.70898 0.654297 7.20703 0.654297 7.92969C0.654297 8.65234 1.16211 9.15039 1.9043 9.15039H13.418L15.6348 9.02344L13.0566 11.377L10.8691 13.584C10.6445 13.7988 10.498 14.0918 10.498 14.4531C10.498 15.1074 10.9961 15.6055 11.6992 15.6055C12.002 15.6055 12.2949 15.4883 12.5586 15.2344L18.9648 8.82812C19.209 8.59375 19.3457 8.26172 19.3457 7.92969Z'
                                  fill='white'
                                />
                              </svg>
                            </div>
                          </div>
                          <div className='pt-3 pr-2'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl"
                              }
                              onClick={() => {
                                handleNewChainItem(index, item[3]);
                              }}>
                              <svg
                                width='12'
                                height='11'
                                viewBox='0 0 12 11'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M0.763184 5.41699C0.763184 5.26042 0.818197 5.12712 0.928223 5.01709C1.04248 4.90283 1.17578 4.8457 1.32812 4.8457H5.43506V0.745117C5.43506 0.592773 5.49007 0.461589 5.6001 0.351562C5.71012 0.237305 5.84342 0.180176 6 0.180176C6.15658 0.180176 6.28988 0.237305 6.3999 0.351562C6.51416 0.461589 6.57129 0.592773 6.57129 0.745117V4.8457H10.6719C10.8242 4.8457 10.9554 4.90283 11.0654 5.01709C11.1797 5.12712 11.2368 5.26042 11.2368 5.41699C11.2368 5.57357 11.1797 5.70687 11.0654 5.81689C10.9554 5.92692 10.8242 5.98193 10.6719 5.98193H6.57129V10.0889C6.57129 10.2412 6.51416 10.3724 6.3999 10.4824C6.28988 10.5967 6.15658 10.6538 6 10.6538C5.84342 10.6538 5.71012 10.5967 5.6001 10.4824C5.49007 10.3724 5.43506 10.2412 5.43506 10.0889V5.98193H1.32812C1.17578 5.98193 1.04248 5.92692 0.928223 5.81689C0.818197 5.70687 0.763184 5.57357 0.763184 5.41699Z'
                                  fill='white'
                                />
                              </svg>
                            </div>
                          </div>
                          <div className='pt-3'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-red-level3 hover:bg-red-accent rounded-xl"
                              }
                              onClick={() => {
                                handleRemoveChainItem(index);
                              }}>
                              <div className='flex flex-col justify-center'>
                                <svg
                                  width='12'
                                  height='11'
                                  viewBox='0 0 12 11'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M1.98828 3.21875H16.002C16.666 3.21875 17.2422 2.65234 17.2422 1.95898C17.2422 1.27539 16.666 0.699219 16.002 0.699219H1.98828C1.34375 0.699219 0.748047 1.27539 0.748047 1.95898C0.748047 2.65234 1.34375 3.21875 1.98828 3.21875Z'
                                    fill='white'
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <select
                      value={item[0]}
                      name='messageType'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 0, event.target.value)
                      }>
                      <option hidden>Select Message Type</option>
                      <option>SystemMessage</option>
                      <option>HumanMessage</option>
                      <option>AIMessage</option>
                      <option>SearchMessage</option>
                      <option>ImageMessage</option>
                      <option>MathMessage</option>
                      <option>SpeakMessage</option>
                      <option>SingMessage</option>
                    </select>
                  </div>
                  <div>
                    <LongerTextArea
                      className='w-full'
                      placeholder='Chain Prompt'
                      onChangeText={(text) =>
                        handleChangeChainItem(index, 1, text)
                      }
                      value={item[1]}
                    />
                  </div>
                  <div>
                    {outputChain[index][0] !== "" ? (
                      <div>
                        <div className='bg-gray-level1 p-3 rounded-xl break-words'>
                          {outputChain[index][0]}

                          {outputChain[index][1] !== "" ? (
                            <div className='flex flex-row justify-center pt-4'>
                              <img
                                src={outputChain[index][1]}
                                className='rounded-xl'></img>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='grid grid-cols-3 gap-4'>
                    <select
                      value={item[4]}
                      name='saveLocation'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 4, event.target.value)
                      }>
                      <option hidden>Select Save Location</option>
                      <option>none</option>
                      <option>metaData</option>
                      <option>chunk</option>
                      <option>chunk_list</option>
                      <option>object</option>
                    </select>
                    <select
                      value={item[5]}
                      name='parseType'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 5, event.target.value)
                      }>
                      <option hidden>Select Parse Type</option>
                      <option>string</option>
                      <option>json</option>
                      <option>array</option>
                      <option>float</option>
                    </select>
                    <Input
                      className='rounded-2xl text-gray-level3'
                      placeholder='Save Key'
                      type='test'
                      onChangeText={(text) =>
                        handleChangeChainItem(index, 2, text)
                      }
                      value={item[2]}
                    />
                  </div>
                </div>
              ) : (
                <div className='grid grid-cols-1 gap-3 mt-3 mb-8 ml-24'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-row'>
                      <div className='pt-3 pr-2'>
                        <div
                          className={
                            "btn-sm font-medium text-gray-white bg-purple-level3 hover:bg-purple-vibrant rounded-xl"
                          }
                          onClick={() => handleRunChain(index)}>
                          {chainLoading[index] ? (
                            <div className='flex items-center justify-center'>
                              <svg
                                role='status'
                                className='inline w-3 h-3 text-blue-generateSpin animate-spin'
                                viewBox='0 0 100 101'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                  fill='#692e87'
                                />
                                <path
                                  d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </div>
                          ) : (
                            <svg
                              width='12'
                              height='11'
                              viewBox='0 0 16 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M1.78516 17.6992C2.21484 17.6992 2.5957 17.5527 3.08398 17.2695L14.6758 10.5508C15.5449 10.0527 15.9355 9.63281 15.9355 8.95898C15.9355 8.29492 15.5449 7.875 14.6758 7.36719L3.08398 0.648438C2.5957 0.365234 2.21484 0.21875 1.78516 0.21875C0.935547 0.21875 0.300781 0.873047 0.300781 1.91797V16C0.300781 17.0547 0.935547 17.6992 1.78516 17.6992Z'
                                fill='white'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div className='font-bold text-xl pt-3'>
                        Chunk {index + 1} 👉
                      </div>
                    </div>
                    <div className=''>
                      <div className='flex flex-row justify-between'>
                        <div className='flex flex-row'>
                          <div className='pt-3 pr-2'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl"
                              }
                              onClick={() => {
                                handleUnchunkChain(index);
                              }}>
                              <svg
                                width='15'
                                height='11'
                                viewBox='0 0 20 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M0.654297 7.92969C0.654297 8.26172 0.800781 8.59375 1.03516 8.82812L7.45117 15.2344C7.70508 15.4883 8.00781 15.6055 8.31055 15.6055C9.01367 15.6055 9.50195 15.1074 9.50195 14.4531C9.50195 14.0918 9.35547 13.7988 9.13086 13.584L6.95312 11.377L4.375 9.02344L6.5918 9.15039H18.1055C18.8477 9.15039 19.3457 8.65234 19.3457 7.92969C19.3457 7.20703 18.8477 6.70898 18.1055 6.70898H6.5918L4.375 6.83594L6.95312 4.48242L9.13086 2.27539C9.35547 2.05078 9.50195 1.75781 9.50195 1.40625C9.50195 0.742188 9.01367 0.244141 8.31055 0.244141C8.00781 0.244141 7.70508 0.371094 7.43164 0.644531L1.03516 7.03125C0.800781 7.26562 0.654297 7.59766 0.654297 7.92969Z'
                                  fill='white'
                                />
                              </svg>
                            </div>
                          </div>
                          <div className='pt-3 pr-2'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-gray-level3 hover:bg-gray-level4 rounded-xl"
                              }
                              onClick={() => {
                                handleNewChainItem(index, item[3]);
                              }}>
                              <svg
                                width='12'
                                height='11'
                                viewBox='0 0 12 11'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M0.763184 5.41699C0.763184 5.26042 0.818197 5.12712 0.928223 5.01709C1.04248 4.90283 1.17578 4.8457 1.32812 4.8457H5.43506V0.745117C5.43506 0.592773 5.49007 0.461589 5.6001 0.351562C5.71012 0.237305 5.84342 0.180176 6 0.180176C6.15658 0.180176 6.28988 0.237305 6.3999 0.351562C6.51416 0.461589 6.57129 0.592773 6.57129 0.745117V4.8457H10.6719C10.8242 4.8457 10.9554 4.90283 11.0654 5.01709C11.1797 5.12712 11.2368 5.26042 11.2368 5.41699C11.2368 5.57357 11.1797 5.70687 11.0654 5.81689C10.9554 5.92692 10.8242 5.98193 10.6719 5.98193H6.57129V10.0889C6.57129 10.2412 6.51416 10.3724 6.3999 10.4824C6.28988 10.5967 6.15658 10.6538 6 10.6538C5.84342 10.6538 5.71012 10.5967 5.6001 10.4824C5.49007 10.3724 5.43506 10.2412 5.43506 10.0889V5.98193H1.32812C1.17578 5.98193 1.04248 5.92692 0.928223 5.81689C0.818197 5.70687 0.763184 5.57357 0.763184 5.41699Z'
                                  fill='white'
                                />
                              </svg>
                            </div>
                          </div>
                          <div className='pt-3'>
                            <div
                              className={
                                "btn-sm font-medium text-gray-white bg-red-level3 hover:bg-red-accent rounded-xl"
                              }
                              onClick={() => {
                                handleRemoveChainItem(index);
                              }}>
                              <div className='flex flex-col justify-center'>
                                <svg
                                  width='12'
                                  height='11'
                                  viewBox='0 0 12 11'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    d='M1.98828 3.21875H16.002C16.666 3.21875 17.2422 2.65234 17.2422 1.95898C17.2422 1.27539 16.666 0.699219 16.002 0.699219H1.98828C1.34375 0.699219 0.748047 1.27539 0.748047 1.95898C0.748047 2.65234 1.34375 3.21875 1.98828 3.21875Z'
                                    fill='white'
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <select
                      value={item[0]}
                      name='messageType'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 0, event.target.value)
                      }>
                      <option hidden>Select Message Type</option>
                      <option>SystemMessage</option>
                      <option>HumanMessage</option>
                      <option>AIMessage</option>
                      <option>SearchMessage</option>
                      <option>ImageMessage</option>
                      <option>MathMessage</option>
                      <option>SpeakMessage</option>
                      <option>SingMessage</option>
                    </select>
                  </div>
                  <div>
                    <LongerTextArea
                      className='w-full'
                      placeholder='Chain Prompt'
                      onChangeText={(text) =>
                        handleChangeChainItem(index, 1, text)
                      }
                      value={item[1]}
                    />
                  </div>
                  <div>
                    {outputChain[index][0] !== "" ? (
                      <div>
                        <div className='bg-gray-level1 p-3 rounded-xl break-words'>
                          {outputChain[index][0]}

                          {outputChain[index][1] !== "" ? (
                            <div className='flex flex-row justify-center pt-4'>
                              <img
                                src={outputChain[index][1]}
                                className='rounded-xl'></img>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='grid grid-cols-3 gap-4'>
                    <select
                      value={item[4]}
                      name='saveLocation'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 4, event.target.value)
                      }>
                      <option hidden>Select Save Location</option>
                      <option>none</option>
                      <option>metaData</option>
                      <option>chunk</option>
                      <option>chunk_list</option>
                      <option>object</option>
                    </select>
                    <select
                      value={item[5]}
                      name='parseType'
                      className='w-full text-gray-text1 rounded-xl bg-gray-level1 p-3 pr-3'
                      onChange={(event) =>
                        handleChangeChainItem(index, 5, event.target.value)
                      }>
                      <option hidden>Select Parse Type</option>
                      <option>string</option>
                      <option>json</option>
                      <option>array</option>
                      <option>float</option>
                    </select>
                    <Input
                      className='rounded-2xl text-gray-level3'
                      placeholder='Save Key'
                      type='test'
                      onChangeText={(text) =>
                        handleChangeChainItem(index, 2, text)
                      }
                      value={item[2]}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default QuorummPartial;
