import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function LearnMorePartial() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className='bg-gray-black'>
      <div className='text-center w-full pt-20 pl-10 pr-10'>
        <div className='flex flex-row justify-center'>
          <span className='text-pink-accent text-3xl font-semibold'>
            We Are The Difference Between Fake & Pure Fiction
          </span>
        </div>
        <div className='flex flex-row justify-center'>
          <span className='text-gray-text1 font-semibold'>
            A New World Powered By Generative AI
          </span>
        </div>
        <div className='pt-24'>
          <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Phone.webp'></img>
        </div>
        <div className='py-24 lg:px-24 md:px-12 sm:px-0'>
          <span className='text-gray-text1 font-semibold text-2xl'>
            <span className='text-gray-white'>
              {" "}
              Social media has become a hub for sharing fake/inauthentic
              content.{" "}
            </span>
            We want to build a new world where you explore and create content
            that is vivid and real. The only way to do that is to enter a world
            of pure <span className='text-pink-accent'>Fiction</span>. Welcome
            to the future of content.
          </span>
        </div>
        <a
          className='btn-sm font-medium text-gray-level1 bg-gray-text2 hover:bg-gray-text1 rounded-xl'
          href='https://airtable.com/shrCOgsI67sGHGmhy'
          target='_blank'>
          <svg
            width='20'
            height='19'
            viewBox='0 0 24 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M23.25 16.8584L23.2354 2.16602C23.2354 1.02344 22.4736 0.24707 21.3164 0.24707H6.60938C5.52539 0.24707 4.73438 1.06738 4.73438 2.04883C4.73438 3.01562 5.56934 3.80664 6.58008 3.80664H11.8975L17.5664 3.60156L14.9443 5.93066L1.42383 19.4658C1.04297 19.8467 0.823242 20.3301 0.823242 20.7988C0.823242 21.751 1.70215 22.6592 2.68359 22.6592C3.16699 22.6592 3.62109 22.4541 4.0166 22.0586L17.5518 8.53809L19.8955 5.91602L19.6611 11.3652V16.8877C19.6611 17.9131 20.4521 18.748 21.4336 18.748C22.415 18.748 23.25 17.9277 23.25 16.8584Z'
              fill='#EAEAEA'
            />
          </svg>

          <span className='pl-2'>Join the Waitlist</span>
        </a>
        <div className='pt-24'>
          <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Phone.webp'></img>
        </div>
        <div className='py-24 lg:px-24 md:px-12 sm:px-0'>
          <div>
            <span className='text-pink-accent font-bold text 2xl'>
              Personalization
            </span>
          </div>
          <div>
            <span className='text-gray-white font-semibold text-4xl'>
              A Feed That Feeds Your Interests
            </span>
          </div>
          <div>
            <span className='text-gray-text1 font-semibold text-2xl'>
              As you scroll, Fiction gets a better read on your interests, and
              generates brand new content made just for you, from scratch.
              Brahma, our AI, is an author writing stories just for you. These
              must-reads include everything that you love.
            </span>
          </div>
        </div>
        <div className='pt-24'>
          <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Phone.webp'></img>
        </div>
        <div className='py-24 lg:px-24 md:px-12 sm:px-0'>
          <div>
            <span className='text-pink-accent font-bold text 2xl'>
              Interactive Map
            </span>
          </div>
          <div>
            <span className='text-gray-white font-semibold text-4xl'>
              A Birds-Eye View Of All Fiction Content.
            </span>
          </div>
          <div>
            <span className='text-gray-text1 font-semibold text-2xl'>
              Fiction has mapped out an entire world of content, and you can
              explore it all. With a quick tap or swipe, dive into any story
              imaginable—from fantasy to thrillers to classics. Plus, find out
              what’s popular in areas and discover content from creators around
              the world.
            </span>
          </div>
        </div>
        <div className='py-24 lg:px-24 md:px-12 sm:px-0'>
          <a
            className='btn-sm font-medium text-pink-accent bg-gray-text2 hover:bg-gray-text1 rounded-xl'
            href='https://airtable.com/shrCOgsI67sGHGmhy'
            target='_blank'>
            <svg
              width='20'
              height='19'
              viewBox='0 0 24 23'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M23.25 16.8584L23.2354 2.16602C23.2354 1.02344 22.4736 0.24707 21.3164 0.24707H6.60938C5.52539 0.24707 4.73438 1.06738 4.73438 2.04883C4.73438 3.01562 5.56934 3.80664 6.58008 3.80664H11.8975L17.5664 3.60156L14.9443 5.93066L1.42383 19.4658C1.04297 19.8467 0.823242 20.3301 0.823242 20.7988C0.823242 21.751 1.70215 22.6592 2.68359 22.6592C3.16699 22.6592 3.62109 22.4541 4.0166 22.0586L17.5518 8.53809L19.8955 5.91602L19.6611 11.3652V16.8877C19.6611 17.9131 20.4521 18.748 21.4336 18.748C22.415 18.748 23.25 17.9277 23.25 16.8584Z'
                fill='#FF76C7'
              />
            </svg>
            <span className='pl-2'>Access Waitlist</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default LearnMorePartial;
