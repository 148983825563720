import React from "react";
import { ErrorMessage } from "@hookform/error-message";

const Input = React.forwardRef((props, ref) => (
  <div className={props.className}>
    <input
      className='w-full px-3 text-gray-text1 outline-none bg-gray-level1 placeholder-gray-text1 rounded-xl py-3'
      value={props.value}
      name={props.name}
      type={props.type ? props.type : "text"}
      placeholder={props.placeholder}
      ref={ref}
      maxLength={props.maxLength}
      onChange={(event) =>
        props.onChangeText ? props.onChangeText(event.target.value) : ""
      }
    />
  </div>
));

export default Input;
