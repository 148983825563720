import React, { useState } from "react";

function ValuesPartial() {
  return (
    <div>
      <div className='flex flex-wrap m-auto align-center justify-items-center text-center w-full pt-20 pl-10 pr-10 mb-8'>
        {/* Hero content */}
        <div className='w-full flex flex-col justify-center'>
          <div
            className='lg:text-5xl md:text-4xl sm:text-3xl font-semibold leading-tighter tracking-tighter mb-4 md:text-center sm:text-left mx-1 w-full'
            data-aos='fade-up'>
            <p className='flex flex-col text-green-values'>Our Values</p>
          </div>
          <div
            className='lg:text-4xl md:text-3xl sm:text-2xl font-semibold leading-tighter tracking-tighter mb-4 md:text-center sm:text-left mx-1 w-full'
            data-aos='fade-up'>
            <p className='flex flex-col justify-center'>
              We Make "For You" Possible
            </p>
          </div>
          <div className='lg:px-24 lg:mx-0 md:mx-0 md:px-24 sm:px-0'>
            <div
              class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 auto-rows-max gap-8 pt-24'
              data-aos='fade-up'>
              <div>
                <p className='lg:text-3xl md:text-3xl sm:text-xl font-semibold leading-tighter tracking-tighter mb-4 mx-1 w-full text-left'>
                  Quasi is the leading hub for all uses of creative AI.
                </p>
                <p className='text-left text-gray-500 text-xl'>
                  Generative AI will revolutionize the way we create and consume
                  content. It allows us to generate new concepts within seconds
                  and allows us to explore possibilities that would have been
                  impossible with traditional methods. Quasi is a platform that
                  connects creators and consumers with the tools and resources
                  they need to harness the power of generative AI. <br /> <br />{" "}
                  At Quasi, our values are reflected in the tagline
                  "Personalize, Create, Evolve". These values guide our approach
                  to everything we do, from the way we develop our technology to
                  the way we interact with our customers and partners.
                </p>
              </div>
              <div className='flex justify-center w-full'>
                <img
                  src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Ouroboros_Hero.webp'
                  height={500}
                  width={500}
                />
              </div>
            </div>
            <div
              class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 auto-rows-max gap-8 pt-24'
              data-aos='fade-up'>
              <div className='flex justify-center w-full'>
                <img
                  src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Mushroom_Hero.webp'
                  height={500}
                  width={500}
                />
              </div>
              <div>
                <p className='text-left text-gray-500 text-xl'>
                  We believe that personalization is at the heart of generative
                  AI, and we are committed to empowering our users to create and
                  enjoy curated creations made just for them. We believe that
                  creativity is the driving force behind generative AI and we
                  are dedicated to providing our users with the tools and
                  resources they need to unleash their creativity. And we
                  believe that evolution is essential to the success of
                  generative AI, and we are committed to continually evolving
                  our technology and design new interactions that feel novel and
                  natural to everyone. Our values <br /> <br /> of
                  personalization, creativity, and evolution are the foundation
                  of our company, and they are reflected in everything we do.
                  Join us as we build the next generation of creative tools and
                  empowering a new wave of creators and innovators with
                  generative AI.
                  <br /> <br /> - Written By Quasi AI
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValuesPartial;
