import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import CreatingPartial from "../partials/CreatingPartial";
import FictionFooter from "../partials/FictionFooter";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

let searchParams = new URLSearchParams(window.location.pathname);
var creationId;
var medium;

const Creation = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
    var i = 0;
    for (let p of searchParams) {
      if (i == 0) {
        creationId = p[1];
      }
      if (i == 1) {
        medium = p[1];
      }
      i++;
    }
  }, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Creation</title>
        <meta name='description' content='Quasi Fiction | Creation' />
        <meta property='og:title' content='Quasi Fiction' />
        <meta
          name='apple-itunes-app'
          content='app-id=1662191941'
          app-argument={
            "fiction://creation?creationID=" + creationId + "&medium=" + medium
          }
        />
      </MetaTags>

      {/*  Site header */}
      {/* <FictionHeader /> */}
      {/*  Page content */}
      <main className='bg-gray-black'>
        {/*  Page sections */}

        <CreatingPartial />
      </main>

      {/*  Site footer */}
      <FictionFooter />
    </div>
  );
};

export default Creation;
