import React, { useState } from "react";

function WorkingPartial() {
  return (
    <div>
      <div className='flex flex-wrap m-auto align-center justify-items-center text-center w-full pt-20 pl-10 pr-10'>
        {/* Hero content */}
        <div className='w-full flex flex-col justify-center'>
          <div class='grid overflow-hidden lg:grid-cols-2 md:grid-cols-1 lg:px-0 md:px-24 sm:px-0 auto-rows-max gap-8 pb-8'>
            <div class='w-full bg-gray-secondaryBox' data-aos='fade-up'>
              <div className='p-8'>
                <div className='lg:px-12 md:px-6 sm:w-full'>
                  <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Business_Hero.webp' />
                </div>
                <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                  Quasi Business
                </p>
                <p className='lg:text-2xl md:text-xl sm:text-md flex flex-col text-left font-medium'>
                  Optimize & Enhance Productivity Using AI.
                </p>
                <div className='flex justify-start pt-3'>
                  <a
                    href='https://quasi.business'
                    target='_blank'
                    className={
                      "btn-sm font-medium text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText rounded-2xl"
                    }>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.5 11.5723L15.4902 1.77734C15.4902 1.01562 14.9824 0.498047 14.2109 0.498047H4.40625C3.68359 0.498047 3.15625 1.04492 3.15625 1.69922C3.15625 2.34375 3.71289 2.87109 4.38672 2.87109H7.93164L11.7109 2.73438L9.96289 4.28711L0.949219 13.3105C0.695312 13.5645 0.548828 13.8867 0.548828 14.1992C0.548828 14.834 1.13477 15.4395 1.78906 15.4395C2.11133 15.4395 2.41406 15.3027 2.67773 15.0391L11.7012 6.02539L13.2637 4.27734L13.1074 7.91016V11.5918C13.1074 12.2754 13.6348 12.832 14.2891 12.832C14.9434 12.832 15.5 12.2852 15.5 11.5723Z'
                        fill='#2C0147'
                      />
                    </svg>
                    <span className='pl-2'>See Business Tools</span>
                  </a>
                </div>
              </div>
            </div>
            <div class='bg-blue-primaryBox' data-aos='fade-up'>
              <div className='p-8'>
                <div className='lg:px-12 md:px-6 sm:w-full'>
                  <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Blog_Hero.webp' />
                </div>
                <p className='lg:text-4xl md:text-2xl sm:text-xl flex flex-col text-left font-semibold'>
                  Quasi Blog
                </p>
                <div>
                  <p className='lg:text-2xl md:text-xl sm:text-md flex flex-col text-left font-medium w-full'>
                    Share Your Voice
                  </p>
                  <div className='flex justify-start pt-3'>
                    <a
                      href='https://quasi.market/business'
                      target='_blank'
                      className={
                        "btn-sm font-medium text-blue-primaryText bg-blue-quasi hover:bg-blue-hoverBox hover:text-blue-hoverText rounded-2xl"
                      }>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.5 11.5723L15.4902 1.77734C15.4902 1.01562 14.9824 0.498047 14.2109 0.498047H4.40625C3.68359 0.498047 3.15625 1.04492 3.15625 1.69922C3.15625 2.34375 3.71289 2.87109 4.38672 2.87109H7.93164L11.7109 2.73438L9.96289 4.28711L0.949219 13.3105C0.695312 13.5645 0.548828 13.8867 0.548828 14.1992C0.548828 14.834 1.13477 15.4395 1.78906 15.4395C2.11133 15.4395 2.41406 15.3027 2.67773 15.0391L11.7012 6.02539L13.2637 4.27734L13.1074 7.91016V11.5918C13.1074 12.2754 13.6348 12.832 14.2891 12.832C14.9434 12.832 15.5 12.2852 15.5 11.5723Z'
                          fill='#003150'
                        />
                      </svg>
                      <span className='pl-2'>Browse Creations</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkingPartial;
