import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function FictionPartial() {
  useEffect(() => {}, []);

  return (
    <section>
      <div
        className='relative h-screen bg-cover bg-center'
        style={{
          backgroundImage:
            "url(https://storage.googleapis.com/quasi-a39a6.appspot.com/fiction_space.webp)",
        }}>
        <div className='absolute inset-0 flex flex-col justify-center items-center'>
          <img
            data-aos='fade-up'
            src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Fiction_Header.png'
            height={200}
            width={200}></img>
          <div className='mb-8'></div>
          <div
            className='lg:text-5xl md:text-5xl sm:text-3xl font-bold leading-tighter tracking-tighter mb-4 text-center'
            data-aos='fade-up'>
            <p className='flex flex-col justify-center text-white'>
              Create
              <span className='text-pink-accent'>Captivating Stories</span>{" "}
              Tailored to Your Interests.
            </p>
          </div>
          <div className='mb-8'></div>
          <a href='https://airtable.com/shrCOgsI67sGHGmhy'>
            <img
              data-aos='fade-up'
              src='https://storage.googleapis.com/quasi-a39a6.appspot.com/App_Store.png'
              height={200}
              width={200}></img>
          </a>
        </div>
      </div>
      <div data-aos='fade-up'>
        <div className='flex md:flex-row sm:flex-col px-4 md:pb-4 sm:pb-24'>
          <div>
            <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/PersonalizationImage.webp'></img>
          </div>
          <div className='flex flex-col justify-center'>
            <p className='text-2xl text-gray-level3'>CUSTOMIZATION</p>
            <p className='text-2xl text-white text-pink-accent'>
              Your Universe, Our AI
            </p>
            <p className='text-white'>
              At the end of every chapter, you get to decide how the next
              chapter goes. Select from one of 3 curated options or write what
              you want to happen next. It’s up to you.
            </p>
          </div>
        </div>
      </div>
      <div data-aos='fade-up'>
        <div className='flex md:flex-row sm:flex-col px-4 md:pb-4 sm:pb-24'>
          <div className='flex flex-col justify-center'>
            <p className='text-2xl text-gray-level3'>PERSONALIZATION</p>
            <p className='text-2xl text-white text-pink-accent'>
              A Feed That Feeds Your Imagination
            </p>
            <p className='text-white'>
              As you scroll, Fiction gets a better read on your interests, and
              generates brand new content made just for you, from scratch. It’s
              like having an author writing stories just for you. These
              must-reads include everything that you love.
            </p>
          </div>
          <div>
            <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/CollaborationImage.webp'></img>
          </div>
        </div>
      </div>
      <div data-aos='fade-up'>
        <div className='flex md:flex-row sm:flex-col px-4 md:pb-4 sm:pb-24'>
          <div>
            <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/ExplorationImage.webp'></img>
          </div>
          <div className='flex flex-col justify-center'>
            <p className='text-2xl text-gray-level3'>EXPLORATION</p>
            <p className='text-2xl text-white text-pink-accent'>
              A Birds-Eye View Of All Stories
            </p>
            <p className='text-white'>
              Fiction has an entire world of content, and you can explore it
              all. With a quick tap or swipe, dive into any story
              imaginable—from fantasy to thrillers to classics. Plus, discover
              content from other creators on Fiction.
            </p>
          </div>
        </div>
      </div>
      <div data-aos='fade-up'>
        <div className='flex md:flex-row sm:flex-col px-4 md:pb-4 sm:pb-24'>
          <div className='flex flex-col justify-center'>
            <p className='text-2xl text-gray-level3'>COLLABORATION</p>
            <p className='text-2xl text-white text-pink-accent'>
              Stories That Go Everywhere
            </p>
            <p className='text-white'>
              All your friends come ready to share stories on Fiction — so
              whether you’re creating or reading, you can keep the conversation
              going.
            </p>
          </div>
          <div>
            <img src='https://storage.googleapis.com/quasi-a39a6.appspot.com/CustomizationImage.webp'></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FictionPartial;
