import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function UserPartial() {
  const [globalUserId, setGlobalUserId] = useState("");

  let searchParams = new URLSearchParams(window.location.pathname);

  const fetchData = async () => {
    try {
      var userId;
      for (let p of searchParams) {
        userId = p[1];
        break;
      }
      setGlobalUserId(userId);
      console.log("userId: " + userId);
      window.location.href = "fiction://profile?userID=" + userId;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <div></div>;
}

export default UserPartial;
