import React, { useState } from "react";
import MediaQuery from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { FaRegChartBar } from "react-icons/fa";
import { GiElectric } from "react-icons/gi";
import { FaSlidersH } from "react-icons/fa";
import { PiRobotFill } from "react-icons/pi";

const Sidebar = ({ onTabChange, currentTabSelected }) => {
  // This is the history hook
  const history = useHistory();

  // Renders each item in the list
  const renderListItem = (index, title, onPress, image) => {
    return (
      <li
        className={`w-11/12 py-4 ml-2 rounded-sm text-white mb-0.5 last:mb-0 mr-5  ${
          currentTabSelected === index && "bg-purple-vibrant opacity-60"
        }`}>
        <div
          onClick={() => {
            if (onPress) {
              onPress();
            } else {
              onTabChange(index);
            }
          }}
          className={
            "text-gray-level4 hover:text-black transition duration-150 cursor-pointer"
          }>
          <div className={"flex flex-grow items-center"}>
            <div className={"flex-shrink-0 mx-4 h-7 w-1/12 "}>{image}</div>
            <div />
            <hr className='my-2' />
            <span
              className={`text-sm lg:text-md font-medium  ${
                currentTabSelected === index && "text-white"
              }`}>
              {title}
            </span>
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className='w-1/7 min-w-48 max-w-64'>
      {/* Sidebar */}
      <div
        className={`fixed z-40 left-0 top-0 static left-auto top-auto translate-x-0 transform h-full w-1/7 min-w-48 flex-shrink-0 py-5 transition-transform duration-200 ease-in-out ${"translate-x-0  bg-white  shadow-xl "}`}>
        {/* Sidebar header */}

        <div className='flex justify-between pr-3 sm:px-2 pb-10'>
          {/* Logo */}
          <div
            className='block px-4 pt-10 items-center cursor-pointer'
            onClick={() => history.push("/Home")}>
            <img
              className='w-auto rounded bg-black'
              src={
                "https://storage.googleapis.com/quasi-a39a6.appspot.com/quasi_studio.png"
              }
              alt='quasi-studio'
              class='responsive'
              width='400'
              height='400'
            />
          </div>
          <div className='pb-10'></div>
        </div>

        <ul className='mt-5 ml-3 mr-3'>
          <div>
            {renderListItem(
              1,
              "Barhma",
              null,
              <FaRegChartBar
                color={currentTabSelected === 1 ? "#FFFFFF" : ""}
              />
            )}
            {renderListItem(
              2,
              "Analytics",
              null,
              <FaSlidersH color={currentTabSelected === 2 ? "#FFFFFF" : ""} />
            )}
            {renderListItem(
              3,
              "Quorum",
              null,
              <PiRobotFill color={currentTabSelected === 3 ? "#FFFFFF" : ""} />
            )}
            {renderListItem(
              4,
              "Backend",
              null,
              <GiElectric color={currentTabSelected === 4 ? "#FFFFFF" : ""} />
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
