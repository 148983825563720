// this file will contain the Axios setup link between API and Front-end
import merlinApp from "../AxiosSetup.js";
import firebase from "firebase/app";
import "firebase/auth";

// POST request functions
export const getCreation = (creationID) => {
  return merlinApp.post("/getCreationPreview", {
    creationID: creationID,
  });
};

export const uploadQuorum = (quorum_json) => {
  return merlinApp.post("/uploadQuorum", {
    quorum_json: quorum_json,
  });
};

export const runQuorum = (quorum_json, components) => {
  return merlinApp.post("/runQuorum", {
    quorum_json: quorum_json,
    components: components,
  });
};

export const testBrahma = (env, medium, recType, recAction) => {
  return merlinApp.post("/testBrahma", {
    env: env,
    medium: medium,
    recType: recType,
    recAction: recAction,
  });
};

export const getBackendStatus = () => {
  return merlinApp.get("/getBackendStatus");
};

export const getBackendQueues = () => {
  return merlinApp.get("/getBackendQueues");
};

// Method is going to log a specific event in Cloud Firestore
export const logFirestoreEvent = (eventName, eventPaylod) => {
  if (window.location.host !== "www.quasi.ventures") {
    if (eventName === "404 Error") {
      console.log({ eventName, eventPaylod });
    }
  } else {
    return merlinApp.post("/log", {
      data: {
        key: "RH5cFnBB0t",
        event_name: eventName,
        event_payload: eventPaylod,
      },
    });
  }
};
