import React from "react";
import { useEffect, useState } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import QuorummPartial from "../partials/QuorumPartial";

import Wrapper from "../utils/Wrapper";
import Sidebar from "../partials/Sidebar";
import GalleryPartial from "../partials/GalleryPartial";
import BackendPartial from "../partials/BackendPartial";
import AnalyticsPartial from "../partials/AnalyticsPartial";
import BrahmaPartial from "../partials/BrahmaPartial";

const Quorum = (props) => {
  const [tabSelected, setTabSelected] = useState(1);
  useEffect(() => {}, []);

  const tabs = [
    <div key={1} className='text-center '>
      <div className='pb-10'></div>
      <div className='pb-20'>
        <p className='h4'>Testing</p>
      </div>
    </div>,
    <div key={2} className='text-center '>
      <BrahmaPartial />
    </div>,
    <div key={3} className='text-center '>
      <AnalyticsPartial />
    </div>,
    <div key={4} className=''>
      <QuorummPartial />
    </div>,
    <div key={4} className='text-center '>
      <BackendPartial />
    </div>,
  ];

  return (
    <div className={"flex justify-between h-full"}>
      <Sidebar
        onTabChange={(newTabIndex) => setTabSelected(newTabIndex)}
        currentTabSelected={tabSelected}
      />
      <Wrapper isGrey={true} dontShowHeader={true}>
        <div className='mx-auto'>{tabs[tabSelected]}</div>
      </Wrapper>
    </div>
  );
};

export default Quorum;
